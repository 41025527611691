export const getApiClient = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const request = async (url: string) => {
    /* eslint-disable */
    const response = await fetch(`${apiUrl}/${url}`);

    return response.json();
  };

  return { request };
};
