import { useEffect, useState } from 'react';
import { useAnalyticsApiAdapter } from '../adapters/useAnalyticsApiAdapter';
import { useSearchParams } from 'react-router-dom';
import { ApiAnalyticsInfo } from '../domain/types/ApiAnalyticsInfo';

export const useLoadAnalyticsInfo = () => {
  const { getAnalyticsInfo } = useAnalyticsApiAdapter();
  const [info, setInfo] = useState<ApiAnalyticsInfo>();
  const [searchParams] = useSearchParams();

  const loadAnalyticsInfo = async () => {
    try {
      const info = await getAnalyticsInfo(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      setInfo(info);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    loadAnalyticsInfo();
  }, [searchParams]);

  return { info };
};
