import { getApiClient } from '../../../infrastructure/api/getApiClient';
import { ApiAnalyticsData } from '../domain/types/ApiAnalyticsData';
import { ApiGameTimeline } from '../domain/types/ApiGameTimeline';
import { ApiEnds } from '../domain/types/ApiEnds';
import { ApiAnalyticsInfo } from '../domain/types/ApiAnalyticsInfo';
import { ApiSteps } from '../domain/types/ApiSteps';

export const useAnalyticsApiAdapter = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { request } = getApiClient();

  const getAnalytics = (type: string, id: number, hash: string): Promise<ApiAnalyticsData> =>
    request(`api/admin/analytics/${type}/${id}/${hash}`);

  const getGameTimeline = (type: string, id: number, hash: string): Promise<ApiGameTimeline> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/game-timeline`);

  const getEnds = (type: string, id: number, hash: string): Promise<ApiEnds> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/ends`);

  const getAnalyticsInfo = (type: string, id: number, hash: string): Promise<ApiAnalyticsInfo> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/data`);

  const getSteps = (type: string, id: number, hash: string): Promise<ApiSteps> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/steps`);

  const getResults = (type: string, id: number, hash: string, email: string): Promise<{ success: boolean }> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/results?email=${email}`);

  const calculate = (type: string, id: number, hash: string): Promise<{ success: boolean; jobId: number }> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/clear-cache`);

  const checkCalculation = (type: string, id: number, hash: string, jobId: number): Promise<{ success: boolean }> =>
    request(`api/admin/analytics/${type}/${id}/${hash}/check-calculation?jobId=${jobId}`);

  const getDataSetLink = (type: string, id: number, hash: string) =>
    `${apiUrl}/api/admin/analytics/${type}/${id}/${hash}/download`;

  return {
    getAnalytics,
    getGameTimeline,
    getEnds,
    getAnalyticsInfo,
    getSteps,
    getResults,
    getDataSetLink,
    calculate,
    checkCalculation,
  };
};
