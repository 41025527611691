import { ApiSteps } from './types/ApiSteps';
import { ChartData } from './types/ChartData';

export const getAnalyticsData = (steps?: ApiSteps) => {
  if (!steps) {
    return [];
  }

  const chartData: ChartData[] = [];

  Object.keys(steps.answers).forEach((answer) => {
    steps.answers[answer].forEach((value) => {
      chartData.push({
        name: answer,
        data: value,
      });
    });
  });

  return chartData;
};

export const analyticsToPercent = (value: number, total: number) =>
  value !== null ? Number(((value * 100) / total).toFixed()) : null;

export const wrapLabels = (labels?: string[]) => {
  if (!labels) {
    return labels;
  }

  return labels.map((label) => {
    const parts = label.split(' ');
    const newLabel: string[] = [];
    let newLabelPart: string[] = [];

    parts.forEach((value) => {
      newLabelPart.push(value);

      if (newLabelPart.length >= 4) {
        newLabel.push(newLabelPart.join(' '));
        newLabelPart = [];
      }
    });

    if (newLabelPart.length) {
      newLabel.push(newLabelPart.join(' '));
    }

    return newLabel;
  });
};
