import { useChart } from '../../../../components/chart';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardHeader } from '@mui/material';
import React, { useEffect } from 'react';
import { analyticsToPercent } from '../../domain/Analytics';

interface DetailedAnalyticsProps {
  title: string;
  all: number[];
  chartLabels: string[];
}

export default function CompletionRate({ title, chartLabels, all }: DetailedAnalyticsProps) {
  const welcome = all[0];
  const max = analyticsToPercent(all[0], welcome);
  const chartData = [
    {
      name: 'All',
      data: all.map((value) => analyticsToPercent(value, welcome)),
    },
  ];

  const chartOptions = useChart({
    labels: chartLabels,
    legend: {
      show: false,
    },
    fill: {
      opacity: 0.5,
      type: 'solid',
    },
    xaxis: {
      labels: {
        rotate: -80,
        rotateAlways: true,
        trim: true,
        minHeight: 150,
      },
    },
    yaxis: {
      min: 0,
      max,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (y === null) {
            return null;
          }

          if (typeof y !== 'undefined') {
            return `${y.toFixed()}%, Visits: ${((y * welcome) / 100).toFixed()}`;
          }
          return y;
        },
      },
    },
  });

  useEffect(() => {
    setTimeout(() => {
      const indeces = [
        chartLabels.findIndex((value) => value === 'welcome'),
        chartLabels.findIndex((value) => value === 'finish'),
        chartLabels.findIndex((value) => value === 'external'),
      ];

      indeces.forEach((index) => {
        const element = document.querySelector('#completion-rate .apexcharts-xaxis-texts-g')?.children[index];
        const title = element?.querySelector('tspan');

        if (!title) {
          return;
        }

        title.style.fontWeight = 'bold';
        title.style.fontSize = '120%';
      });
    }, 1000);
  }, [chartLabels]);

  return (
    <Card>
      <CardHeader title={title} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart id="completion-rate" type="area" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
