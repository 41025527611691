import { useEffect, useState } from 'react';
import { useAnalyticsApiAdapter } from '../adapters/useAnalyticsApiAdapter';
import { useSearchParams } from 'react-router-dom';
import { ApiSteps } from '../domain/types/ApiSteps';

export const useLoadSteps = () => {
  const { getSteps } = useAnalyticsApiAdapter();
  const [steps, setSteps] = useState<ApiSteps>();
  const [searchParams] = useSearchParams();

  const loadSteps = async () => {
    try {
      const data = await getSteps(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      setSteps(data);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    loadSteps();
  }, [searchParams]);

  return { steps };
};
