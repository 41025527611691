import { Chip } from '@mui/material';
import React from 'react';
import { ApiStepsData } from '../../../domain/types/ApiSteps';

interface DetailedAnalyticsAnswersProps {
  data: ApiStepsData;
}

export default function DetailedAnalyticsAnswers({ data }: DetailedAnalyticsAnswersProps) {
  return (
    <>
      {data.answers &&
        Object.keys(data.answers).map((answer) => (
          <>
            <div style={{ marginTop: '10px' }}>
              <Chip
                key={answer}
                label={`${Math.round((data.answers[answer] * 100) / data.all)}% / ${data.answers[answer]}`}
                style={{ borderRadius: '5px' }}
                variant="outlined"
              />{' '}
              <small>{answer}</small>
            </div>
          </>
        ))}
    </>
  );
}
