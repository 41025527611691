// ----------------------------------------------------------------------

export default function Paper() {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          border: '1px solid rgba(145, 158, 171, 0.24)',
          boxShadow: 'none !important',
        },
      },
    },
  };
}
