// @mui
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
// utils

// ----------------------------------------------------------------------

interface GameInfoProps {
  list: any[];
}

export default function GameSummary({ list }: GameInfoProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: 'repeat(3, 1fr)',
        height: '100%;',
      }}
    >
      {list.map((site) => (
        <Paper key={site.name} variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
          <Box sx={{ mb: 0.5 }}>{site.icon}</Box>

          <Typography variant="h6">{site.value}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {site.name}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
}
