import { useEffect, useState } from 'react';
import { useAnalyticsApiAdapter } from '../adapters/useAnalyticsApiAdapter';
import { useSearchParams } from 'react-router-dom';
import { ApiEnds } from '../domain/types/ApiEnds';

export const useLoadGameEnds = () => {
  const { getEnds } = useAnalyticsApiAdapter();
  const [gameEnds, setGameEnds] = useState<ApiEnds>({ titles: [], ends: [], redirect: [], satisfaction: [] });
  const [searchParams] = useSearchParams();

  const loadGameEnds = async () => {
    try {
      const data = await getEnds(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      setGameEnds(data);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    loadGameEnds();
  }, [searchParams]);

  return { gameEnds };
};
