import { Box, Card, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { ApiSteps } from '../../../domain/types/ApiSteps';
import DetailedAnalyticsAnswers from './DetailedAnalyticsAnswers';

interface DetailedAnalyticsByQuestionsProps {
  title: string;
  steps: ApiSteps['steps'];
}

export default function DetailedAnalyticsByQuestions({ title, steps }: DetailedAnalyticsByQuestionsProps) {
  return (
    <Card>
      <CardHeader title={title} />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {Object.keys(steps).map(
          (questionKey) =>
            steps[questionKey].answers && (
              <>
                <Typography variant="subtitle1" key={questionKey}>
                  {questionKey}
                </Typography>
                <DetailedAnalyticsAnswers data={steps[questionKey]} />
                <br />
              </>
            )
        )}
      </Box>
    </Card>
  );
}
