import { Helmet } from 'react-helmet-async';
// @mui
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import { useLoadAnalytics } from '../modules/analitics/useCases/useLoadAnalytics';
import GameTimeLine from '../modules/analitics/ui/components/GameTimeLine.tsx';
import GameInfo from '../modules/analitics/ui/components/GameInfo';
import { AppCurrentSubject, AppWidgetSummary } from '../sections/@dashboard/app';
import { useLoadGameTimeline } from '../modules/analitics/useCases/useLoadGameTimeline';
import { useTheme } from '@mui/material/styles';
import { useLoadGameEnds } from '../modules/analitics/useCases/useLoadGameEnds';
import { useLoadAnalyticsInfo } from '../modules/analitics/useCases/useLoadAnalyticsInfo';
import GameSummary from '../modules/analitics/ui/components/GameSummary';
import { fShortenNumber } from '../utils/formatNumber';
import { useLoadSteps } from '../modules/analitics/useCases/useLoadSteps';
import CompletionRate from '../modules/analitics/ui/components/CompletionRate';
import GetResults from '../modules/analitics/ui/components/GetResults';
import DetailedAnalyticsByQuestions from '../modules/analitics/ui/components/detailedAnalytics/DetailedAnalyticsByQuestions';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { test, loading, goToGame, dataSetLink, startCalculate } = useLoadAnalytics();
  const { gameTimelineData } = useLoadGameTimeline();
  const { gameEnds } = useLoadGameEnds();
  const { info } = useLoadAnalyticsInfo();
  const { steps } = useLoadSteps();

  return (
    <>
      <Backdrop sx={{ background: '#f9fafb', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Helmet>
        <title> {`${test?.title} Stats | DataJuice`} </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: '150px 150px 1fr 1fr',
                alignItems: 'center',
              }}
            >
              <Button variant="contained" onClick={goToGame}>
                Go to game
              </Button>
              <Button variant="contained" onClick={startCalculate}>
                Update
              </Button>
              <Link target="_blank" href={dataSetLink}>
                Get Dataset
              </Link>
              <GetResults />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {test?.title}
                </Typography>

                {test?.picture ? <img src={test?.picture} /> : ''}
                <br />
                <GameSummary
                  list={[
                    {
                      name: 'Total Game Audience',
                      value: info?.totalGameAudience,
                      icon: <Iconify icon={'ph:play-bold'} color="#1877F2" width={32} />,
                    },
                    {
                      name: 'Game Final Audience',
                      value: info?.gameFinalAudience,
                      icon: <Iconify icon={'mdi:success-circle-outline'} color="#1877F2" width={32} />,
                    },
                    {
                      name: 'External Success',
                      value: info?.externalSuccess,
                      icon: <Iconify icon={'gridicons:external'} color="#1877F2" width={32} />,
                    },
                    {
                      name: 'Completion Rate',
                      value: `${fShortenNumber(info?.gameCompletionRate)}%`,
                      icon: <Iconify icon={'mingcute:lightning-line'} color="#1877F2" width={32} />,
                    },
                    {
                      name: 'External Transition Rate',
                      value: `${fShortenNumber(info?.externalSuccessRate)}%`,
                      icon: (
                        <Iconify
                          icon={'streamline:interface-award-trophy-reward-rating-trophy-social-award-media'}
                          color="#1877F2"
                          width={32}
                        />
                      ),
                    },
                    {
                      name: 'Satisfaction Score',
                      value: `${fShortenNumber(info?.satisfactionScoreRate)}%`,
                      icon: <Iconify icon={'ph:star-bold'} color="#1877F2" width={32} />,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            {steps ? <CompletionRate title="Completion Rate" chartLabels={steps.titles} all={steps.all} /> : ''}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6">Conversions</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Seen" total={info?.seen} color="info" icon={'solar:play-bold'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Game Audience"
              total={info?.totalGameAudience}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Game Final Audience"
              total={info?.gameFinalAudience}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="External Success"
              total={info?.externalSuccess}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Non Engaged Clients"
              total={info?.nonEngagedClients}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Non Engaged Clients Rate"
              total={info?.nonEngagedClientsRate}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Completion Rate"
              total={info?.gameCompletionRate}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="External Transition Rate"
              total={info?.externalSuccessRate}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6">Quality</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Satisfaction Score"
              total={info?.satisfactionScoreRate}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Rated the game" total={info?.allRate} color="info" icon={'solar:play-bold'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Rated the game"
              total={info?.ratedTheGame}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Sharing Rate"
              total={info?.shareRate}
              fractionDigits={2}
              percent={true}
              color="info"
              icon={'solar:play-bold'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Low Rated" total={info?.lowRated} color="info" icon={'solar:play-bold'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Top Rated" total={info?.topRated} color="info" icon={'solar:play-bold'} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppCurrentSubject
              title="Finishes"
              chartLabels={gameEnds?.titles}
              chartData={[
                { name: 'From all finishes', data: gameEnds?.ends },
                { name: 'From external transition', data: gameEnds?.redirect },
                { name: 'Satisfaction score', data: gameEnds?.satisfaction },
              ]}
              chartColors={[...Array(gameEnds?.titles?.length)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={10}>
            <GameTimeLine
              title="Game timeline"
              chartLabels={gameTimelineData?.map(({ date }) => date) ?? []}
              chartData={[
                {
                  name: '',
                  type: 'column',
                  fill: 'solid',
                  data: gameTimelineData?.map(({ count }) => count) ?? [],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <GameInfo
              list={[
                {
                  name: 'Game type',
                  value: test?.type,
                  icon: <Iconify icon={'carbon:idea'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Duration',
                  value: test?.lifetime + ' days',
                  icon: <Iconify icon={'iconoir:calendar'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Quality rate',
                  value: test?.stars_rate,
                  icon: <Iconify icon={'material-symbols:star'} color="#f1b609" width={32} />,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {steps ? <DetailedAnalyticsByQuestions title="Detailed analytics" steps={steps.steps} /> : ''}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
