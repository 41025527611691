import { useEffect, useState } from 'react';
import { useAnalyticsApiAdapter } from '../adapters/useAnalyticsApiAdapter';
import { useSearchParams } from 'react-router-dom';
import { ApiGameTimeline } from '../domain/types/ApiGameTimeline';

export const useLoadGameTimeline = () => {
  const { getGameTimeline } = useAnalyticsApiAdapter();
  const [gameTimelineData, setGameTimelineData] = useState<ApiGameTimeline>();
  const [searchParams] = useSearchParams();

  const loadGameTimeline = async () => {
    try {
      const data = await getGameTimeline(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      setGameTimelineData(data);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    loadGameTimeline();
  }, [searchParams]);

  return { gameTimelineData };
};
