// @mui
import { Box, Button, Input } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAnalyticsApiAdapter } from '../../adapters/useAnalyticsApiAdapter';
// utils

// ----------------------------------------------------------------------

export default function GetResults() {
  const [searchParams] = useSearchParams();
  const { getResults } = useAnalyticsApiAdapter();
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(false);

  const search = async () => {
    if (!email) {
      return alert('Введите Email');
    }

    setDisabled(true);

    try {
      await getResults(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash')),
        email
      );

      alert('Документ будет подготовлен и отправлен на почту');
    } catch (e) {
      return e;
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: 'repeat(2, 1fr)',
      }}
    >
      <Input
        placeholder="Email"
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
        }}
        style={{ width: '200px' }}
      />
      <Button variant="contained" disabled={disabled} onClick={search}>
        Get results
      </Button>
    </Box>
  );
}
