import { useEffect, useState } from 'react';
import { useAnalyticsApiAdapter } from '../adapters/useAnalyticsApiAdapter';
import { Test } from '../domain/types/Test';
import { useSearchParams } from 'react-router-dom';

export const useLoadAnalytics = () => {
  const { getAnalytics, getDataSetLink, calculate, checkCalculation } = useAnalyticsApiAdapter();
  const [test, setTest] = useState<Test>();
  const [dataSetLink, setDataSetLink] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  const loadAnalytics = async () => {
    try {
      setLoading(true);
      const { test } = await getAnalytics(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      setTest(test);

      setDataSetLink(
        getDataSetLink(
          String(searchParams.get('type')),
          Number(searchParams.get('id')),
          String(searchParams.get('hash'))
        )
      );

      setLoading(false);
    } catch (e) {
      return e;
    }
  };

  const goToGame = () => {
    window.open(test?.url, '_blank');
  };

  const startCalculate = async () => {
    try {
      const { jobId } = await calculate(
        String(searchParams.get('type')),
        Number(searchParams.get('id')),
        String(searchParams.get('hash'))
      );

      alert('Пересчет статистики запущен. Пожалуйста, подождите.');

      setLoading(true);

      setInterval(async () => {
        const { success } = await checkCalculation(
          String(searchParams.get('type')),
          Number(searchParams.get('id')),
          String(searchParams.get('hash')),
          jobId
        );

        if (success) {
          window.location.reload();
        }
      }, 15000);
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    loadAnalytics();
  }, [searchParams]);

  return { test, loading, goToGame, dataSetLink, startCalculate };
};
